<template>
    <div class="view pa24">
        <div class="mb20">
            <el-tabs v-model="webType">
                <el-tab-pane label="在场车辆" name="onSiteVehicles"></el-tab-pane>
                <el-tab-pane label="车辆进出记录" name="vehicleAccess"></el-tab-pane>
                <el-tab-pane label="手动放行记录" name="manualRelease"></el-tab-pane>
                <el-tab-pane label="月租开闸记录" name="monthlyRentGateOpening"></el-tab-pane>
                <el-tab-pane label="访客记录" name="visitor"></el-tab-pane>
                <el-tab-pane label="无牌车记录" name="unlicensedCar"></el-tab-pane>
                <el-tab-pane label="不在场车辆记录" name="absentVehicle"></el-tab-pane>
                <el-tab-pane label="未进场记录" name="notMobilized"></el-tab-pane>
                <el-tab-pane label="黑名单记录" name="blacklist"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType === 'onSiteVehicles'">
            <onSiteVehiclesTable />
        </div>
        <div v-if="webType === 'vehicleAccess'">
            <vehicleAccessTable />
        </div>
        <div v-if="webType === 'manualRelease'">
            <manualReleaseTable />
        </div>
        <div v-if="webType === 'monthlyRentGateOpening'">
            <monthlyRentGateOpeningTable />
        </div>
        <div v-if="webType === 'visitor'">
            <visitorTable />
        </div>
        <div v-if="webType === 'unlicensedCar'">
            <unlicensedCarTable />
        </div>
        <div v-if="webType === 'absentVehicle'">
            <absentVehicleTable />
        </div>
        <div v-if="webType === 'notMobilized'">
            <notMobilizedTable />
        </div>
        <div v-if="webType === 'blacklist'">
            <blacklistTable />
        </div>
    </div>
</template>

<script>
import onSiteVehiclesTable from "./pages/onSiteVehiclesTable";
import vehicleAccessTable from "./pages/vehicleAccessTable";
import manualReleaseTable from "./pages/manualReleaseTable";
import monthlyRentGateOpeningTable from "./pages/monthlyRentGateOpeningTable";
import visitorTable from "./pages/visitorTable";
import unlicensedCarTable from "./pages/unlicensedCarTable";
import absentVehicleTable from "./pages/absentVehicleTable";
import notMobilizedTable from "./pages/notMobilizedTable";
import blacklistTable from "./pages/blacklistTable";
export default {
    name: "dataRecord",
    components: {
        onSiteVehiclesTable,
        vehicleAccessTable,
        manualReleaseTable,
        monthlyRentGateOpeningTable,
        visitorTable,
        unlicensedCarTable,
        absentVehicleTable,
        notMobilizedTable,
        blacklistTable,
    },
    data() {
        return {
            webType: "onSiteVehicles",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>