<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <el-button type="primary" @click="searchFun">导出数据</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="name" align="center" label="车牌号" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="用户名称" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="出场通道" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="出场时间" show-overflow-tooltip />
                <el-table-column prop="name" align="center" label="出场类型" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button class="mr10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel" :destroy-on-close="true"
            width="50%" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="等级名称" prop="levelName">
                    <el-input v-model="formData" placeholder="请输入等级名称"></el-input>
                </el-form-item>
                <el-form-item label="升级顺序" prop="levelSort">
                    <el-input placeholder="请输入升级顺序" v-model="formData" type="number" />
                </el-form-item>
                <el-form-item label="积分价格" prop="levelTarget">
                    <el-input v-model="formData" placeholder="请输入积分价格"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "absentVehicle",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {

            },
            rules: {
                levelName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入等级名称",
                },
                levelSort: {
                    required: true,
                    trigger: "blur",
                    message: "请输入升级顺序",
                },
            },
            formType: "",
            updateId: 0,
        };
    },
    filters: {

    },
    created() {

    },
    methods: {
        /**@method 搜索 */
        searchFun() { },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {

                }
            });
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage
            };
            try {
                this.loading = true;

                this.loading = false;

                // const { data } = result;
                // this.tableData = data.pageInfo.list;
                // this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增...";
            this.formType = "add";
            this.formData = {

            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑...";
            this.formType = "update";
            this.updateId = val.id;
            this.formData = {

            };
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>